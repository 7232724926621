<template>
  <div>
    <v-row class="ml-12">
      <v-col cols="7">
        <editor :value="exercise.body.content" disabled></editor>
      </v-col>
      <v-col cols="12" class="p-0 m-0"></v-col>
      <v-col cols="4">
        <template v-for="(data, i) in exercise.body.data_correct_option">
          <v-row :key="i">
            <v-col cols="4" class="d-flex pt-0 pb-0">
              <h4 class="mt-2 mr-4">
                <template v-if="type_expand === 0">
                  {{
                    i + section.offset_question + exercise.offset_question + 1
                  }}.
                </template>
                <template v-if="type_expand === 1"> {{ i + 1 }}. </template>
              </h4>
              <label class="mt-2 text-body-1">Đáp án option {{ i + 1 }}</label>
            </v-col>
            <v-col cols="7" class="p-0">
              <v-select
                class=""
                dense
                :value="data.correct"
                :items="data.option"
                filled
                readonly
              ></v-select>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Editor from "@/components/ckeditor5/Editor";
export default {
  name: "SingleChoiceParagraphType",
  components: { Editor },
  props: ["section", "exercise", "type_expand"],
};
</script>

<style scoped></style>
