<template>
  <div>
    <template v-for="(correct_item, iBody) in exercise.body.list_correct">
      <div :key="iBody">
        <v-row >
          <v-col cols="1" class="text-center pr-0">
            <h4 class="ml-4">
              <template v-if="type_expand == 0">
                {{
                  iBody + section.offset_question + exercise.offset_question + 1
                }}.
              </template>
              <template v-if="type_expand == 1"> {{ iBody + 1 }}. </template>
            </h4>
          </v-col>
          <v-col cols="7" class="pl-0 d-flex">
            <h5 style="color: #6495ed" class="">
              {{ iBody | getAlphabetCharacterFromIndex }}.
            </h5>
            <span class="black--text font-weight-medium text-h6 mb-4 ml-4">Question {{iBody+1}} </span>
            <span class="text-h6 ml-2 mr-2">-</span>
            <span v-html="correct_item.option.value"></span>
          </v-col>
        </v-row>
      </div>
    </template>

  </div>
</template>

<script>
export default {
  name: "MatchingPairType",
  props: ["section", "exercise", "type_expand"],
}
</script>

<style scoped>

</style>